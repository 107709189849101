.product-filters_grid {
  display: flex;
  gap: 12px;
}

.product-filters h3 {
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

/* .product-filters {
  margin-top: 30px;
} */

.products {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

/* .products_listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  margin-top: 35px;
} */

.product-pagination {
  margin-top: 35px;
}

@media (max-width: 600px) {
  .products {
    padding-top: 20px;
  }
}
