.product-single {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

.product-single_bottom {
  display: flex;
  gap: 70px;
  margin-top: 80px;
}

.product-single_img {
  text-align: right;
}

.product-single_img img {
  width: 160px;
  height: 580px;
  object-fit: cover;
}

h2.product-single_heading {
  color: #2e2e2e;
  font-size: 30px;
}

li.ingredients-list-item {
  display: flex;
  gap: 15px;
  font-size: 16px;
}

.product-single_ingredients,
.product-single_make,
.product-single_img {
  width: 100%;
}

span.list-item_quantity {
  min-width: 25%;
}

.product-single_ingredients-list,
.product-single_steps-list {
  margin-top: 25px;
  list-style: none;
  font-size: 16px;
}

li.steps-list-item span {
  display: block;
}

span.list-item_index {
  display: inline-block;
  float: left;
  height: 100%;
  margin-right: 5px;
}

span.list-item_desc {
  margin-left: 20px;
  font-size: 12px;
  margin-top: 8px;
}

span.list-item_heading {
  font-weight: 700;
  color: #000000;
}

span.list-item_heading {
  margin-left: 20px;
}

span.list-item_index {
  color: black;
  font-weight: 600;
}

li.steps-list-item,
li.ingredients-list-item {
  margin-bottom: 35px;
}

.product-single_bottom-btns {
  display: flex;
  gap: 20px;
  margin-top: 120px;
}

p.product-single_paragraph {
  line-height: 20px;
  margin-top: 12px;
}

.product-single_last {
  width: 80%;
}

@media (max-width: 600px) {
  .product-single_bottom {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .product-single_img {
    text-align: center;
  }

  .product-single_last {
    width: 100%;
    text-align: center;
  }

  h2.product-single_heading {
    font-size: 24px;
    text-align: center;
  }

  li.ingredients-list-item {
    font-size: 14px;
  }

  .product-single_bottom-btns {
    flex-direction: column;
    margin-top: 60px;
  }

  .product-single_bottom {
    margin-top: 30px;
  }
}
