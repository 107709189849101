.display {
  display: flex;
  gap: 35px;
  align-items: center;
  margin-bottom: 30px;
}

.display_img {
  max-width: 430px;
  text-align: center;
  overflow: hidden;
}

.display_img img {
  width: 100%;
  object-fit: contain;
  text-align: center;
}

h1.display_title {
  color: #c1272d;
  font-weight: 600;
  font-size: 40px;
}

p.display_desc {
  line-height: 30px;
  margin-top: 15px;
}

.display_stars-container {
  margin-top: 20px;
}

.display_stars-total {
  margin-left: 12px;
  font-size: 12px;
  background-color: #c1272d;
  color: white;
  padding: 3px 9px;
  border-radius: 6px;
}

.display_stars {
  display: flex;
  font-size: 20px;
  align-items: center;
  gap: 3px;
}

@media (max-width: 600px) {
  .display {
    flex-direction: column-reverse;
  }
}
