.home {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_btns {
  display: flex;
  gap: 30px;
  position: relative;
  top: 70px;
}

h1.home_main-heading {
  font-size: 7.2em;
  color: white;
  font-weight: 600;
  width: 50%;
  text-align: center;
  margin-bottom: 35px;
  word-spacing: 1px;
  letter-spacing: 1px;
}

form.home_search {
  background-color: white;
  height: 60px;
  overflow: hidden;
  border-radius: 100px;
}

form.home_search * {
  border: none;
  outline: none;
  height: 100%;
}

form.home_search input {
  padding: 10px 20px;
  width: 570px;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
}

button.home_search-btn {
  background-color: #c1272d;
  color: white;
  padding: 0px 60px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  margin-left: 13px;
  border-radius: 100px;
  cursor: pointer;
}

form.home_search select {
  padding: 0px 11px;
  /* width: 116px; */
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
}

form.home_search input::placeholder {
  color: #bfbfbf;
}

@media (max-width: 600px) {
  .home {
    font-size: 4px;
  }

  h1.home_main-heading {
    width: 90%;
    font-weight: 700;
    font-size: 32px;
  }

  form.home_search {
    width: 90%;
    overflow: visible;
  }

  form.home_search input {
    width: 100%;
    border-radius: 100px;
  }

  form.home_search select {
    width: 27%;
    background: white;
    border-radius: 100px;
    height: 38px;
    margin-right: 5px;
    font-size: 12px;
  }

  button.home_search-btn {
    margin: 0;
    width: 70%;
    height: 38px;
    margin-top: 10px;
    font-size: 14px;
  }

  .home_btns {
    /* display: none; */
    margin-top: 15px;
    gap: 10px;
    flex-wrap: wrap;
  }

  form.home_search {
    height: 40px;
  }

  form.home_search input {
    font-size: 14px;
  }
}
