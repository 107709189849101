.para{
    margin-bottom: 10px;
    color: #000;
}

.list-first li {
    font-size: 1.4em;
    margin: 10px;
    margin-left: 40px;
    color: #000;
    font-family: "Raleway", sans-serif !important;
}

.list-second li {
    font-size: 1.4em;
    margin: 10px;
    margin-left: 26px;
    color: #000;
    font-family: "Raleway", sans-serif !important;
}

ul.list-second li {
    list-style: none;
    font-size: 1.4em;
}

.list-second li::before {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.1em solid black;
    border-top: 0.1em solid black;
    transform: rotate(45deg);
    margin-right: 0.5em;
}