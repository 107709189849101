input.custom-input {
  display: block;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d9d9d9;
  font-family: "Raleway";
}

input.custom-input[type="checkbox"] {
  display: inline-block;
  width: auto;
}
