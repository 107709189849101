.login-signup {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-signup .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h1.page-heading {
  font-size: 44px;
  color: black;
  font-weight: 600;
  margin-bottom: 40px;
}

form.login-signup_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-signup_terms-check {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-top: 20px;
}

label[for="login-signup_terms"] a {
  text-decoration: none;
  font-size: inherit;
}

.divider {
  text-align: center;
  font-size: 16px;
  margin: 15px 0px;
}

form.login-signup_form button:first-of-type,
form.login-signup_form button:last-of-type {
  margin-top: 20px;
}

a.already-member {
  margin-top: 20px;
  color: #a1a1a1;
  text-decoration: none;
}
