.icon-box {
  display: inline-flex;
  align-items: center;
  background-color: #c1272d;
  padding: 10px 12px;
  color: white;
  text-decoration: none;
  gap: 10px;
  font-size: 20px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
}

.icon-box_icon {
  color: white;
  font-size: 2.5em;
  display: flex;
  align-items: center;
}

.icon-box_right .icon-box_right-subtitle {
  font-size: 0.7em;
}

.icon-box_right .icon-box_right-title {
  font-size: 1.1em;
  font-weight: 700;
}

@media (max-width: 600px) {
  .icon-box_right .icon-box_right-title {
    font-size: 1em;
  }

  .icon-box {
    font-size: 15px;
  }
}
