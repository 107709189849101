@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap);
:root {
  --defaultPadding: 80px;
}

*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  color: #777777;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .container {
  max-width: 1170px;
  margin: 0 auto;
} */

p,
button,
a {
  font-size: 1.4em;
}

h4 {
  font-size: 1.6em;
}

h1 {
  font-size: 2.4em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.8em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1.2em;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Raleway", sans-serif !important;
}

.MuiPagination-ul {
  justify-content: center;
}

.home {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_btns {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
  top: 70px;
}

h1.home_main-heading {
  font-size: 7.2em;
  color: white;
  font-weight: 600;
  width: 50%;
  text-align: center;
  margin-bottom: 35px;
  word-spacing: 1px;
  letter-spacing: 1px;
}

form.home_search {
  background-color: white;
  height: 60px;
  overflow: hidden;
  border-radius: 100px;
}

form.home_search * {
  border: none;
  outline: none;
  height: 100%;
}

form.home_search input {
  padding: 10px 20px;
  width: 570px;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
}

button.home_search-btn {
  background-color: #c1272d;
  color: white;
  padding: 0px 60px;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  margin-left: 13px;
  border-radius: 100px;
  cursor: pointer;
}

form.home_search select {
  padding: 0px 11px;
  /* width: 116px; */
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
}

form.home_search input::-webkit-input-placeholder {
  color: #bfbfbf;
}

form.home_search input::placeholder {
  color: #bfbfbf;
}

@media (max-width: 600px) {
  .home {
    font-size: 4px;
  }

  h1.home_main-heading {
    width: 90%;
    font-weight: 700;
    font-size: 32px;
  }

  form.home_search {
    width: 90%;
    overflow: visible;
  }

  form.home_search input {
    width: 100%;
    border-radius: 100px;
  }

  form.home_search select {
    width: 27%;
    background: white;
    border-radius: 100px;
    height: 38px;
    margin-right: 5px;
    font-size: 12px;
  }

  button.home_search-btn {
    margin: 0;
    width: 70%;
    height: 38px;
    margin-top: 10px;
    font-size: 14px;
  }

  .home_btns {
    /* display: none; */
    margin-top: 15px;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
  }

  form.home_search {
    height: 40px;
  }

  form.home_search input {
    font-size: 14px;
  }
}

.icon-box {
  display: inline-flex;
  align-items: center;
  background-color: #c1272d;
  padding: 10px 12px;
  color: white;
  text-decoration: none;
  grid-gap: 10px;
  gap: 10px;
  font-size: 20px;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
}

.icon-box_icon {
  color: white;
  font-size: 2.5em;
  display: flex;
  align-items: center;
}

.icon-box_right .icon-box_right-subtitle {
  font-size: 0.7em;
}

.icon-box_right .icon-box_right-title {
  font-size: 1.1em;
  font-weight: 700;
}

@media (max-width: 600px) {
  .icon-box_right .icon-box_right-title {
    font-size: 1em;
  }

  .icon-box {
    font-size: 15px;
  }
}

.drawer-container,
.drawer-container .links {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.drawer-close-icon {
  font-size: 30px;
  text-align: right;
  margin-right: 15px;
  margin-top: 15px;
  font-weight: 800;
  cursor: pointer;
}

.drawer-close-icon:hover {
  color: #c1272d;
}

.drawer-container .links a {
  padding: 15px 40px;
  padding-right: 100px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.drawer-container .links a:hover {
  background-color: #c1272d;
  color: white;
}

.hamburger-icon {
  color: white;
  font-size: 24px;
}

.app-btns {
  display: flex;
  grid-gap: 11px;
  gap: 11px;
  padding: 30px;
  flex-direction: column;
}

.drawer-container {
  height: 100%;
  justify-content: space-between;
}

.header {
  background-color: #c1272d;
  padding: 8px 0px;
}

.header-home {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #c1272da1;
}

.header .container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.header_right {
  margin-left: auto;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.header_brand {
  margin-right: 20px;
}

.header_brand img {
  height: 75px;
}

.header_nav-items a {
  color: white;
  text-decoration: none;
  font-size: 1.6em;
  font-weight: 600;
}

.header_nav-items {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

svg.header_menu-icon {
  font-size: 30px;
}

.header_search {
  background-color: #ffffff99;
  border-radius: 6px;
  overflow: hidden;
}

.header_search input {
  border: none;
  padding: 13px 16px;
  width: 300px;
  font-size: 1.6em;
  height: 45px;
  background-color: transparent;
  color: white;
}

button.header_search-btn {
  border: none;
  background-color: white;
  color: #c1272d;
  padding: 10px 15px;
  font-size: 17px;
  height: 47px;
}

.header-home button.header_search-btn {
  background-color: #c1272d;
  color: white;
}

.header_search input::-webkit-input-placeholder {
  color: white;
}

.header_search input::placeholder {
  color: white;
}

.header_user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.header_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.responsive-menu {
  display: none;
}

@media (max-width: 600px) {
  .header_nav-items {
    display: none;
  }

  .responsive-menu {
    display: block;
  }

  .header_brand img {
    height: 60px;
  }
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-home {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

ul.footer_menu {
  list-style: none;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

ul.footer_menu li a,
ul.footer_menu li {
  text-decoration: none;
  color: black;
}

.footer-home ul.footer_menu li a,
.footer-home ul.footer_menu li,
.footer-home .footer_copyright {
  color: white;
}

.footer_copyright {
  font-size: 14px;
  color: black;
}

.footer {
  padding: 20px 0px;
}

@media (max-width: 600px) {
  .footer_copyright {
    font-size: 12px;
  }
  .footer .container {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .footer-home {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    align-items: end;
    margin-top: auto;
  }
}

select.custom-select {
  border: 1px solid #d6d6d6;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  font-family: "Raleway";
  color: #777777;
  font-size: 16px;
}

.product-card {
  width: 100%;
  text-decoration: none;
}

.product-card_image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
}

.product-card_image {
  width: auto;
  height: 100%;
}

.product-card_top {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 320px;
}

.product-card_heart {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00000099;
  color: white;
  display: flex;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
}

.product-card_stars {
  display: flex;
  font-size: 20px;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}

.product-card_stars-container {
  background-color: #00000099;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px;
}

.product-card_stars-total {
  margin-left: 12px;
  font-size: 12px;
  background-color: white;
  color: #c1272d;
  padding: 3px 9px;
  border-radius: 6px;
}

.product-card_bottom,
.product-card_title {
  font-size: 18px;
  text-align: center;
  color: #c1272d;
  margin-top: 15px;
  text-decoration: none;
}

.product-card_heart-liked {
  background-color: white;
  color: gold;
}

@media (max-width: 600px) {
  .product-card_top {
    height: 200px;
  }

  .product-card_image img {
    object-fit: contain;
  }
}

.product-filters_grid {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.product-filters h3 {
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

/* .product-filters {
  margin-top: 30px;
} */

.products {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

/* .products_listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  margin-top: 35px;
} */

.product-pagination {
  margin-top: 35px;
}

.display {
  display: flex;
  grid-gap: 35px;
  gap: 35px;
  align-items: center;
  margin-bottom: 30px;
}

.display_img {
  max-width: 430px;
  text-align: center;
  overflow: hidden;
}

.display_img img {
  width: 100%;
  object-fit: contain;
  text-align: center;
}

h1.display_title {
  color: #c1272d;
  font-weight: 600;
  font-size: 40px;
}

p.display_desc {
  line-height: 30px;
  margin-top: 15px;
}

.display_stars-container {
  margin-top: 20px;
}

.display_stars-total {
  margin-left: 12px;
  font-size: 12px;
  background-color: #c1272d;
  color: white;
  padding: 3px 9px;
  border-radius: 6px;
}

.display_stars {
  display: flex;
  font-size: 20px;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}

@media (max-width: 600px) {
  .display {
    flex-direction: column-reverse;
  }
}

.product-filters_grid {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.product-filters h3 {
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

/* .product-filters {
  margin-top: 30px;
} */

.products {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

.products_listing {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: space-between; */
  margin-top: 35px;
}

.product-pagination {
  margin-top: 35px;
}

.product-filters_grid {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.product-filters h3 {
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

/* .product-filters {
  margin-top: 30px;
} */

.products {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

/* .products_listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  margin-top: 35px;
} */

.product-pagination {
  margin-top: 35px;
}

.product-single {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

.product-single_bottom {
  display: flex;
  grid-gap: 70px;
  gap: 70px;
  margin-top: 80px;
}

.product-single_img {
  text-align: right;
}

.product-single_img img {
  width: 160px;
  height: 580px;
  object-fit: cover;
}

h2.product-single_heading {
  color: #2e2e2e;
  font-size: 30px;
}

li.ingredients-list-item {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  font-size: 16px;
}

.product-single_ingredients,
.product-single_make,
.product-single_img {
  width: 100%;
}

span.list-item_quantity {
  min-width: 25%;
}

.product-single_ingredients-list,
.product-single_steps-list {
  margin-top: 25px;
  list-style: none;
  font-size: 16px;
}

li.steps-list-item span {
  display: block;
}

span.list-item_index {
  display: inline-block;
  float: left;
  height: 100%;
  margin-right: 5px;
}

span.list-item_desc {
  margin-left: 20px;
  font-size: 12px;
  margin-top: 8px;
}

span.list-item_heading {
  font-weight: 700;
  color: #000000;
}

span.list-item_heading {
  margin-left: 20px;
}

span.list-item_index {
  color: black;
  font-weight: 600;
}

li.steps-list-item,
li.ingredients-list-item {
  margin-bottom: 35px;
}

.product-single_bottom-btns {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 120px;
}

p.product-single_paragraph {
  line-height: 20px;
  margin-top: 12px;
}

.product-single_last {
  width: 80%;
}

@media (max-width: 600px) {
  .product-single_bottom {
    flex-direction: column-reverse;
    grid-gap: 30px;
    gap: 30px;
  }

  .product-single_img {
    text-align: center;
  }

  .product-single_last {
    width: 100%;
    text-align: center;
  }

  h2.product-single_heading {
    font-size: 24px;
    text-align: center;
  }

  li.ingredients-list-item {
    font-size: 14px;
  }

  .product-single_bottom-btns {
    flex-direction: column;
    margin-top: 60px;
  }

  .product-single_bottom {
    margin-top: 30px;
  }
}

.login-signup {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-signup .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h1.page-heading {
  font-size: 44px;
  color: black;
  font-weight: 600;
  margin-bottom: 40px;
}

form.login-signup_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.login-signup_terms-check {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-size: 14px;
  margin-top: 20px;
}

label[for="login-signup_terms"] a {
  text-decoration: none;
  font-size: inherit;
}

.divider {
  text-align: center;
  font-size: 16px;
  margin: 15px 0px;
}

form.login-signup_form button:first-of-type,
form.login-signup_form button:last-of-type {
  margin-top: 20px;
}

a.already-member {
  margin-top: 20px;
  color: #a1a1a1;
  text-decoration: none;
}


.para{
    margin-bottom: 10px;
    color: #000;
}

.list-first li {
    font-size: 1.4em;
    margin: 10px;
    margin-left: 40px;
    color: #000;
    font-family: "Raleway", sans-serif !important;
}

.list-second li {
    font-size: 1.4em;
    margin: 10px;
    margin-left: 26px;
    color: #000;
    font-family: "Raleway", sans-serif !important;
}

ul.list-second li {
    list-style: none;
    font-size: 1.4em;
}

.list-second li::before {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.1em solid black;
    border-top: 0.1em solid black;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-right: 0.5em;
}
input.custom-input {
  display: block;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d9d9d9;
  font-family: "Raleway";
}

input.custom-input[type="checkbox"] {
  display: inline-block;
  width: auto;
}

button.custom-button {
  border: none;
  background-color: #c1272d;
  color: white;
  padding: 15px 10px;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}

button.custom-button:hover {
  opacity: 0.9;
  transition: all ease 0.2s;
}

button.facebook-btn.custom-button,
button.google-btn.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

button.facebook-btn.custom-button {
  background-color: #3b5998;
}

button.google-btn.custom-button {
  background-color: #cd2900;
}

.custom-button_icon {
  font-size: 20px;
}


.product-filters_grid {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.product-filters h3 {
  color: black;
  margin-bottom: 15px;
  font-weight: 600;
}

/* .product-filters {
  margin-top: 30px;
} */

.products {
  padding-top: var(--defaultPadding);
  padding-bottom: var(--defaultPadding);
}

/* .products_listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  margin-top: 35px;
} */

.product-pagination {
  margin-top: 35px;
}

@media (max-width: 600px) {
  .products {
    padding-top: 20px;
  }
}

