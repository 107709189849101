.header {
  background-color: #c1272d;
  padding: 8px 0px;
}

.header-home {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #c1272da1;
}

.header .container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header_right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.header_brand {
  margin-right: 20px;
}

.header_brand img {
  height: 75px;
}

.header_nav-items a {
  color: white;
  text-decoration: none;
  font-size: 1.6em;
  font-weight: 600;
}

.header_nav-items {
  display: flex;
  gap: 20px;
  align-items: center;
}

svg.header_menu-icon {
  font-size: 30px;
}

.header_search {
  background-color: #ffffff99;
  border-radius: 6px;
  overflow: hidden;
}

.header_search input {
  border: none;
  padding: 13px 16px;
  width: 300px;
  font-size: 1.6em;
  height: 45px;
  background-color: transparent;
  color: white;
}

button.header_search-btn {
  border: none;
  background-color: white;
  color: #c1272d;
  padding: 10px 15px;
  font-size: 17px;
  height: 47px;
}

.header-home button.header_search-btn {
  background-color: #c1272d;
  color: white;
}

.header_search input::placeholder {
  color: white;
}

.header_user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.header_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.responsive-menu {
  display: none;
}

@media (max-width: 600px) {
  .header_nav-items {
    display: none;
  }

  .responsive-menu {
    display: block;
  }

  .header_brand img {
    height: 60px;
  }
}
